import { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../../context/AuthContext";
import fetchDataEndpoint from "../../../../utils/get"

const useApi = () => {
    const [userData, setUserData] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    const { currentUser } = useContext(AuthContext);
    const token = currentUser?.token;

    useEffect(() => {
        const fetchData = async () => {
            if (currentUser) {
                await fetchDataEndpoint("/student/portal/", token, setUserData, setError, setLoading);

            }
        };

        fetchData();
    }, [currentUser, token]);

    return { userData, error, loading };
};

export {useApi};