// import { Outlet } from "react-router-dom";
import Navlayout from "./topnav";
// import Footer from "./Footer"


const Dashboard = () => {
    return (
      <>
        <Navlayout />
  
        {/* <Footer /> */}
      </>
    )
  };
export default Dashboard