// // // import React, { useState } from 'react';
// // // import { useApi } from './hook/useApi';
// // // import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// // // import { faBell } from '@fortawesome/free-solid-svg-icons';
// // // // import { Link } from 'react-router-dom';

// // // const Notification = () => {
// // //     const [showPopup, setShowPopup] = useState(false);
// // //     const { messages, unreadCount, error, loading } = useApi();

// // //     const handleBellClick = async () => {
// // //         setShowPopup(prevState => !prevState);
// // //         if (unreadCount > 0) {
// // //             try {
// // //                 await fetch('/student/messages', {
// // //                     method: 'POST',
// // //                     headers: { 'Content-Type': 'application/json' },
// // //                     body: JSON.stringify({}),
// // //                 });
// // //                 // Optionally, refresh the data or adjust state
// // //                 // Note: The useApi hook should handle this
// // //             } catch (err) {
// // //                 console.error('Error marking messages as read:', err);
// // //             }
// // //         }
// // //     };

// // //     const handleMessageClick = async (messageId) => {
// // //         try {
// // //             await fetch(`/student/messages/${messageId}`, {
// // //                 method: 'POST',
// // //                 headers: { 'Content-Type': 'application/json' },
// // //                 body: JSON.stringify({}),
// // //             });
// // //             window.location.href = `/portal/id/${messageId}`;
// // //         } catch (err) {
// // //             console.error('Error marking message as read:', err);
// // //         }
// // //     };

// // //     return (
// // //         <div className="notification-container">
// // //             <div
// // //                 className="bell-icon-container"
// // //                 onClick={handleBellClick}
// // //                 onMouseEnter={() => setShowPopup(true)}
// // //                 onMouseLeave={() => setShowPopup(false)}
// // //                 style={{ cursor: 'pointer' }}
// // //             >
// // //                 <FontAwesomeIcon
// // //                     icon={faBell}
// // //                     className={`bell-icon ${unreadCount > 0 ? 'bell-icon--active' : ''}`}
// // //                     size="lg"
// // //                 />
// // //                 {unreadCount > 0 && <span className="message-count">{unreadCount}</span>}
// // //             </div>

// // //             <div >
// // //                 <div className={`popup ${showPopup ? 'visible' : ''}`}>
// // //                     <h3>Messages</h3>
// // //                     {loading && <p>Loading...</p>}
// // //                     {error && <p>Error fetching messages: {error.message}</p>}

// // //                     {messages.length > 0 ? (
// // //                         <ul className="message-list">
// // //                             {messages
// // //                                 .sort((a, b) => (a.is_read - b.is_read)) // Unread messages first
// // //                                 .map(message => (
// // //                                     <li
// // //                                         key={message.id}
// // //                                         className={`message-item ${!message.is_read ? 'unread' : ''}`}
// // //                                         onClick={() => handleMessageClick(message.id)}
// // //                                     >
// // //                                         {message.message.length > 30
// // //                                             ? `${message.message.substring(0, 30)}...`
// // //                                             : message.message}
// // //                                     </li>
// // //                                 ))}
// // //                         </ul>
// // //                     ) : (
// // //                         <p>No messages</p>
// // //                     )}
// // //                 </div>
// // //             </div>
// // //         </div>
// // //     );
// // // };

// // // export default Notification;

// // import React, { useState } from 'react';
// // import { useApi } from './hook/useApi';
// // import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// // import { faBell } from '@fortawesome/free-solid-svg-icons';
// // import { useNavigate } from 'react-router-dom';

// // const Notification = () => {
// //     const [showPopup, setShowPopup] = useState(false);
// //     const { messages, unreadCount, error, loading } = useApi();
// //     const navigate = useNavigate();

// //     const handleBellClick = async () => {
// //         setShowPopup(prevState => !prevState);
// //         if (unreadCount > 0) {
// //             try {
// //                 await fetch('/student/messages', {
// //                     method: 'POST',
// //                     headers: { 'Content-Type': 'application/json' },
// //                     body: JSON.stringify({}),
// //                 });
// //                 // Optionally, refresh the data or adjust state
// //                 // Note: The useApi hook should handle this
// //             } catch (err) {
// //                 console.error('Error marking messages as read:', err);
// //             }
// //         }
// //     };

// //     const handleMessageClick = (messageId) => {
// //         // Navigate to the details page with the message ID
// //         navigate(`/message/${messageId}`);
// //     };

// //     return (
// //         <div className="notification-container">
// //             <div
// //                 className="bell-icon-container"
// //                 onClick={handleBellClick}
// //                 onMouseEnter={() => setShowPopup(true)}
// //                 onMouseLeave={() => setShowPopup(false)}
// //                 style={{ cursor: 'pointer' }}
// //             >
// //                 <FontAwesomeIcon
// //                     icon={faBell}
// //                     className={`bell-icon ${unreadCount > 0 ? 'bell-icon--active' : ''}`}
// //                     size="lg"
// //                 />
// //                 {unreadCount > 0 && <span className="message-count">{unreadCount}</span>}
// //             </div>

// //             <div>
// //                 <div className={`popup ${showPopup ? 'visible' : ''}`}>
// //                     <h3>Messages</h3>
// //                     {loading && <p>Loading...</p>}
// //                     {error && <p>Error fetching messages: {error.message}</p>}

// //                     {messages.length > 0 ? (
// //                         <ul className="message-list">
// //                             {messages
// //                                 .sort((a, b) => (a.is_read - b.is_read)) // Unread messages first
// //                                 .map(message => (
// //                                     <li
// //                                         key={message.id}
// //                                         className={`message-item ${!message.is_read ? 'unread' : ''}`}
// //                                         onClick={() => handleMessageClick(message.id)}
// //                                     >
// //                                         {message.message.length > 30
// //                                             ? `${message.message.substring(0, 30)}...`
// //                                             : message.message}
// //                                     </li>
// //                                 ))}
// //                         </ul>
// //                     ) : (
// //                         <p>No messages</p>
// //                     )}
// //                 </div>
// //             </div>
// //         </div>
// //     );
// // };

// // export default Notification;



// import React, { useState } from 'react';
// import { useApi } from './hook/useApi';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faBell } from '@fortawesome/free-solid-svg-icons';
// import { useNavigate } from 'react-router-dom';

// const Notification = () => {
//     const [showPopup, setShowPopup] = useState(false);
//     const { messages, unreadCount, error, loading } = useApi();
//     const navigate = useNavigate();

//     const handleBellClick = () => {
//         // Toggle the popup visibility
//         setShowPopup(prevState => !prevState);
//     };

//     const handleMessageClick = async (messageId) => {
//         try {
//             // Mark the specific message as read
//             await fetch(`/student/messages/${messageId}`, {
//                 method: 'POST',
//                 headers: { 'Content-Type': 'application/json' },
//                 body: JSON.stringify({}),
//             });

//             // Navigate to the message details page
//             navigate(`portal/messages/${messageId}`);
//         } catch (err) {
//             console.error('Error marking message as read:', err);
//         }
//     };

//     return (
//         <div className="notification-container">
//             <div
//                 className="bell-icon-container"
//                 onClick={handleBellClick}
//                 onMouseEnter={() => setShowPopup(true)}
//                 onMouseLeave={() => setShowPopup(false)}
//                 style={{ cursor: 'pointer' }}
//             >
//                 <FontAwesomeIcon
//                     icon={faBell}
//                     className={`bell-icon ${unreadCount > 0 ? 'bell-icon--active' : ''}`}
//                     size="lg"
//                 />
//                 {unreadCount > 0 && <span className="message-count">{unreadCount}</span>}
//             </div>

//             <div>
//                 <div className={`popup ${showPopup ? 'visible' : ''}`}>
//                     <h3>Messages</h3>
//                     {loading && <p>Loading...</p>}
//                     {error && <p>Error fetching messages: {error.message}</p>}

//                     {messages.length > 0 ? (
//                         <ul className="message-list">
//                             {messages
//                                 .sort((a, b) => (a.is_read - b.is_read)) // Unread messages first
//                                 .map(message => (
//                                     <li
//                                         key={message.id}
//                                         className={`message-item ${!message.is_read ? 'unread' : ''}`}
//                                         onClick={() => handleMessageClick(message.id)}
//                                     >
//                                         {message.message.length > 30
//                                             ? `${message.message.substring(0, 30)}...`
//                                             : message.message}
//                                     </li>
//                                 ))}
//                         </ul>
//                     ) : (
//                         <p>No messages</p>
//                     )}
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default Notification;

// import React, { useState } from 'react';
// import { useApi } from './hook/useApi';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faBell } from '@fortawesome/free-solid-svg-icons';
// import { useNavigate } from 'react-router-dom';

// const Notification = () => {
//     const [showPopup, setShowPopup] = useState(false);
//     const { messages, unreadCount, error, loading } = useApi();
//     const navigate = useNavigate();

//     const handleBellClick = () => {
//         setShowPopup(prevState => !prevState);
//     };

//     const handleMessageClick = (messageId) => {
//         // Navigate to the message details page
//         navigate(`/portal/message/${messageId}`);
//     };

//     return (
//         <div className="notification-container">
//             <div
//                 className="bell-icon-container"
//                 onClick={handleBellClick}
//                 onMouseEnter={() => setShowPopup(true)}
//                 onMouseLeave={() => setShowPopup(false)}
//                 style={{ cursor: 'pointer' }}
//             >
//                 <FontAwesomeIcon
//                     icon={faBell}
//                     className={`bell-icon ${unreadCount > 0 ? 'bell-icon--active' : ''}`}
//                     size="lg"
//                 />
//                 {unreadCount > 0 && <span className="message-count">{unreadCount}</span>}
//             </div>

//             <div>
//                 <div className={`popup ${showPopup ? 'visible' : ''}`}>
//                     <h3>Messages</h3>
//                     {loading && <p>Loading...</p>}
//                     {error && <p>Error fetching messages: {error.message}</p>}

//                     {messages.length > 0 ? (
//                         <ul className="message-list">
//                             {messages
//                                 .sort((a, b) => (a.is_read - b.is_read)) // Unread messages first
//                                 .map(message => (
//                                     <li
//                                         key={message.id}
//                                         className={`message-item ${!message.is_read ? 'unread' : ''}`}
//                                         onClick={() => handleMessageClick(message.id)}
//                                     >
//                                         {message.message.length > 30
//                                             ? `${message.message.substring(0, 30)}...`
//                                             : message.message}
//                                     </li>
//                                 ))}
//                         </ul>
//                     ) : (
//                         <p>No messages</p>
//                     )}
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default Notification;

import React, { useState } from 'react';
import { useApi } from './hook/useApi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

const Notification = () => {
    const [showPopup, setShowPopup] = useState(false);
    const { messages, unreadCount, error, loading, fetchMessageById } = useApi();
    const navigate = useNavigate();

    const handleBellClick = () => {
        setShowPopup(prevState => !prevState);
    };

    const handleMessageClick = async (messageId) => {
        // Fetch message details by ID before navigating
        await fetchMessageById(messageId);
        // Navigate to the message details page
        navigate(`/portal/read-messages/${messageId}`);
    };

    return (
        <div className="notification-container">
            <div
                className="bell-icon-container"
                onClick={handleBellClick}
                onMouseEnter={() => setShowPopup(true)}
                onMouseLeave={() => setShowPopup(false)}
                style={{ cursor: 'pointer' }}
            >
                <FontAwesomeIcon
                    icon={faBell}
                    className={`bell-icon ${unreadCount > 0 ? 'bell-icon--active' : ''}`}
                    size="lg"
                />
                {unreadCount > 0 && <span className="message-count">{unreadCount}</span>}
            </div>

            <div>
                <div className={`popup ${showPopup ? 'visible' : ''}`}>
                    <h3>Messages</h3>
                    {loading && <p>Loading...</p>}
                    {error && <p>Error fetching messages: {error.message}</p>}

                    {messages.length > 0 ? (
                        <ul className="message-list">
                            {messages
                                .sort((a, b) => (a.is_read - b.is_read)) // Unread messages first
                                .map(message => (
                                    <li
                                        key={message.id}
                                        className={`message-item ${!message.is_read ? 'unread' : ''}`}
                                        onClick={() => handleMessageClick(message.id)}
                                    >
                                        {message.message.length > 30
                                            ? `${message.message.substring(0, 30)}...`
                                            : message.message}
                                    </li>
                                ))}
                        </ul>
                    ) : (
                        <p>No messages</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Notification;
