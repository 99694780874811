import React from 'react';

const HeroSection = () => {
  return (
    <div className="hero-section">
      <div className="hero-content">
        {/* Shape Your Future <br /> */}
        <h1>  Unlock Your Potential <br /> with  Excellence in <br /> <span style={{ color: 'tomato' }}>Education</span> </h1>

        <p className="subheading">
          We are dedicated to nurturing the leaders, innovators, and thinkers of tomorrow.
          <br />
          Step into an environment where learning knows no bounds and excellence is the standard.
        </p>

        <div className="button-group">
          <button className="primary-button">Get Started</button>
          <button className="secondary-button">Learn More</button>
        </div>
      </div>

    </div>
  );
};

export default HeroSection;
